import React from "react";

function Languages() {
  return (
    <div className="section">
      <h1>Languages</h1>
      <p>Hebrew</p>
      <p>Spanish</p>
      <p>English</p>
    </div>
  );
}

export default Languages;
