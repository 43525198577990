import React from "react";

function Hobbies() {
  return (
    <div className="section">
      <h1>Hobbies</h1>
      <p>Martial arts</p>
      <p>Carpentry</p>
      <p>Motorcycles</p>
      <p>Hiking</p>
      <p>Theater</p>
      <p>Dad jokes</p>
    </div>
  );
}

export default Hobbies;
